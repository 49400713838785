<template>
  <div>
    <el-dialog
      class="business-price"
      :title="title"
      width="770px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form size="small">
        <el-form-item
          label="还款方式"
          label-width="160px"
          style="margin-bottom: 10px"
        >
          <el-select
            :disabled="isEdit"
            style="width: 200px"
            v-model="repay_type"
            placeholder="请选择"
          >
            <el-option
              v-for="item in paymentMethodList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-divider />
        <!-- 等额本金 -->
        <div v-if="repay_type == 1">
          <el-form-item
            label="期数"
            label-width="160px"
            style="margin-bottom: 10px"
          >
            <el-input
              style="width: 200px"
              v-model="averageCapitalForm.repay_period_num"
              placeholder="请输入"
              @input="periodNumInput"
            />
            期
          </el-form-item>
          <el-form-item
            label="平台管理费率(%)"
            label-width="160px"
            style="margin-bottom: 10px"
          >
            <div class="flex" style="align-items: center">
              <div>
                <el-input
                  style="width: 200px"
                  v-model="averageCapitalForm.platform_fee_rate"
                  type="number"
                  placeholder="请输入"
                  @input="feeRataInput($event)"
                />
                %
              </div>
              <el-radio-group
                style="margin-left: 60px"
                v-model="averageCapitalForm.platform_fee_pay_assume"
                @change="onFeePayAssumeInput"
              >
                <el-radio :label="1">学员承担</el-radio>
                <el-radio :label="2">机构贴息</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label="商家管理费率(%)"
            label-width="160px"
            style="margin-bottom: 10px"
            v-if="averageCapitalForm.platform_fee_pay_assume == 1"
          >
            <el-input
              style="width: 200px"
              v-model="averageCapitalForm.business_fee_extend"
              oninput="if(value>1)value=1"
              type="number"
              placeholder="请输入"
            />
            %
          </el-form-item>
          <el-form-item
            label="备注"
            label-width="160px"
            style="margin-bottom: 10px"
          >
            <el-input
              style="width: 90%"
              v-model="averageCapitalForm.memo"
              placeholder="请输入"
            />
          </el-form-item>
        </div>
        <!-- 组合还款 -->
        <div v-if="repay_type == 2">
          <el-form-item label="组合方式" label-width="160px">
            <el-select
              style="width: 200px"
              v-model="groupForm.x_repay_type"
              placeholder="请选择"
              @change="onXtypeChanged"
            >
              <el-option
                v-for="item in x_paymentMethodList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 组合方式 -->

          <el-row>
            <el-col :span="12">
              <el-form-item
                label="X段期数"
                label-width="160px"
                style="margin-bottom: 10px"
              >
                <el-input
                  style="width: 150px"
                  v-model="groupForm.x_period_num"
                  type="number"
                  placeholder="请输入"
                  @change="xPeriodNumChange"
                />
                月
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="Y段期数"
                label-width="160px"
                style="margin-bottom: 10px"
              >
                <el-input
                  style="width: 150px"
                  v-model="groupForm.y_period_num"
                  type="number"
                  placeholder="请输入"
                  @change="yPeriodNumChange"
                />
                月
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="还款比例"
                label-width="160px"
                style="margin-bottom: 10px"
              >
                <el-input
                  :disabled="groupForm.x_repay_type == 3"
                  style="width: 150px"
                  v-model="groupForm.x_repay_rate"
                  type="number"
                  placeholder="请输入"
                  @input="onPayRateInput"
                />
                %
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="还款比例"
                label-width="160px"
                style="margin-bottom: 10px"
              >
                <el-input
                  disabled
                  style="width: 150px"
                  v-model="groupForm.y_repay_rate"
                  type="number"
                />
                %
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="平台管理费率(%)"
                label-width="160px"
                style="margin-bottom: 10px"
              >
                <el-input
                  style="width: 150px"
                  v-model="groupForm.x_platform_fee_rate"
                  type="number"
                  placeholder="请输入"
                  @input="xFeeRateInput($event)"
                />
                %
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="平台管理费率(%)"
                label-width="160px"
                style="margin-bottom: 10px"
              >
                <el-input
                  style="width: 150px"
                  v-model="groupForm.y_platform_fee_rate"
                  type="number"
                  placeholder="请输入"
                  @input="yFeeRateInput($event)"
                />
                %
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="" label-width="160px">
                <el-radio-group
                  v-model="groupForm.x_platform_fee_repay_assume"
                >
                  <el-radio :label="1">学员承担</el-radio>
                  <el-radio :label="2">机构贴息</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="" label-width="160px">
                <el-radio-group
                  v-model="groupForm.y_platform_fee_repay_assume"
                >
                  <el-radio :label="1">学员承担</el-radio>
                  <el-radio :label="2">机构贴息</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            label="商家管理费率(%)"
            label-width="160px"
            style="margin-bottom: 10px"
            v-if="
              groupForm.x_platform_fee_repay_assume == 1 &&
              groupForm.y_platform_fee_repay_assume == 1
            "
          >
            <el-input
              style="width: 200px"
              v-model="groupForm.business_fee_extend"
              oninput="if(value>1)value=1"
              type="number"
              placeholder="请输入"
            />
            %
          </el-form-item>
          <el-form-item
            label="首期支付天数"
            label-width="160px"
            style="margin-bottom: 10px"
          >
            <!-- <el-input
              style="width: 200px"
              v-model="groupForm.first_repay_day"
              type="number"
              placeholder="请输入"
            />
            天 -->
            <el-radio-group
              v-model="first_pay_day_status"
              @change="onFirstPayDayStatus"
            >
              <el-radio :label="1">默认（一个月）</el-radio>
              <el-radio :label="2">自定义</el-radio>
            </el-radio-group>
            <div v-if="first_pay_day_status == 2">
              <el-input
                style="width: 200px"
                v-model="groupForm.first_repay_day"
                type="number"
                placeholder="请输入"
                @input="firstRepayDayInput($event)"
              />
              天
            </div>
          </el-form-item>
          <el-form-item
            label="备注"
            label-width="160px"
            style="margin-bottom: 10px"
          >
            <el-input
              style="width: 90%"
              v-model="groupForm.memo"
              placeholder="请输入"
            />
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm()" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  paymentMethodList,
  groupPaymentMethodList,
} from "@/assets/equipment/allSelect.json";
import {
  editPackage,
  createPackage,
  infoPackage,
  businessFeeList,
} from "@/api/package.js";
//
export default {
  name: "other-configuration",
  data() {
    return {
      first_pay_day_status:1,
      isEdit: false,
      dialogFormVisible: false,
      title: "新增报价",
      paymentMethodList: paymentMethodList,
      x_paymentMethodList: groupPaymentMethodList,
      y_paymentMethodList: groupPaymentMethodList,
      repay_type: 1,
      package_name: "",
      package_id: "",
      business_id: "",
      averageCapitalForm: {
        platform_fee_pay_assume: 1,
      },
      groupForm: {
        x_platform_fee_repay_assume: 1,
        y_platform_fee_repay_assume: 1,
      },
      businessFeeList: [],
    };
  },
  methods: {
    async isShow(type,val, business_id) {
      this.dialogFormVisible = true;
      this.business_id = business_id;
      this.getbusinessFeeList();
      this.isEdit = !!val;
      if (type == 'edit') {
        this.title = "修改报价";
        this.package_id = val.package_id;
        this.averageCapitalForm.package_id = val.package_id;
        this.groupForm.package_id = val.package_id;
        this.repay_type = val.repay_type;
        this.getInfo(val.package_id);
      } else {
        this.title = "新增报价";
        this.repay_type = 1;
        this.package_id = "";
      }
    },
    async getInfo(val) {
      const parme = {
        package_id: val,
      };
      const { code, data } = await infoPackage(parme);
      if (code == 200) {
        this.package_name = data.package_name;
        this.repay_type = data.repay_type;
        if (data.repay_type == 1) {
          this.averageCapitalForm = {
            package_id: data.package_id,
            repay_period_num: data.repay_period_num,
            business_fee_extend: data.business_fee_extend,
            platform_fee_rate: data.platform_fee_rate,
            platform_fee_discount_rate: data.platform_fee_discount_rate,
            platform_fee_pay_assume: data.platform_fee_pay_assume,
            memo: data.memo,
          };
        } else {
          if (data.first_repay_day <= 0) {
            this.first_pay_day_status = 1;
          } else {
            this.first_pay_day_status = 2;
          }
          this.groupForm = {
            package_id: data.package_id,
            business_fee_extend: data.business_fee_extend,
            first_repay_day: data.first_repay_day,
            x_repay_rate: data.x_repay_rate,

            x_repay_type: String(data.x_repay_type),
            y_repay_type: String(data.y_repay_type),

            x_period_num: data.x_period_num,
            y_period_num: data.y_period_num,

            x_platform_fee_rate: data.x_platform_fee_rate,
            y_platform_fee_rate: data.y_platform_fee_rate,

            platform_fee_discount_rate: data.platform_fee_discount_rate,

            x_platform_fee_repay_assume: data.x_platform_fee_repay_assume,
            y_platform_fee_repay_assume: data.y_platform_fee_repay_assume,

            memo: data.memo,
          };
        }
      }
    },
    onFirstPayDayStatus(val) {
      if (val == 1) {
        this.groupForm.first_repay_day = 0;
      } else {
        this.groupForm.first_repay_day = "";
      }
    },
    firstRepayDayInput() {
      this.$forceUpdate();
    },
    async getbusinessFeeList() {
      const params = {
        business_id: this.business_id,
      };
      const { code, data } = await businessFeeList(params);
      if (code == 200) {
        this.businessFeeList = data;
      }
    },
    onFeePayAssumeInput(val) {
      if (val == 2) {
        this.averageCapitalForm.business_fee_extend = "";
      }
    },
    periodNumInput(val) {
      if (val != "") {
        if (val > 36) {
          this.$message({
            message: "不能超过36期",
            type: "warning",
          });
          this.averageCapitalForm.platform_fee_rate = "";
        } else if (val < 0) {
          this.$message({
            message: "不能低于0期",
            type: "warning",
          });
          this.averageCapitalForm.platform_fee_rate = "";
        } else {
          this.businessFeeList.map((item) => {
            if (val >= item.stage_count_start && val <= item.stage_count_end) {
              this.averageCapitalForm.platform_fee_rate =
                item.platform_fee_rate;
            }
          });
        }
      } else {
        this.averageCapitalForm.platform_fee_rate = "";
      }
    },
    feeRataInput(e) {
      console.log(e);
      this.$forceUpdate();
    },
    xPeriodNumChange(val) {
      val = Math.min(36, Math.max(1, Math.round(val)));
      this.groupForm.x_period_num = val;

      const feeItem = this.businessFeeList.find((item) => {
        return val >= item.stage_count_start && val <= item.stage_count_end;
      });
      if (feeItem) {
        this.groupForm.x_platform_fee_rate = feeItem.platform_fee_rate;
      }
    },
    onXtypeChanged(val) {
      // 1等额 3先息后本
      if (val == 3) {
        this.groupForm.x_repay_rate = 0;
        this.groupForm.y_repay_rate = 100;

        this.groupForm.x_platform_fee_repay_assume = 1;
        this.groupForm.y_platform_fee_repay_assume = 1;
      }
      if (val == 1) {
        this.groupForm.x_repay_rate = 10;
        this.groupForm.y_repay_rate = 90;
      }
    },
    onPayRateInput(val) {
      if (this.groupForm.x_repay_type == 1 && val < 10) {
        this.$message({
          message: "等额本金时X还款比例不能低于10%",
          type: "warning",
        });
        this.$forceUpdate();
      } else {
        val = Math.min(99, Math.max(0, Math.round(val)));
        this.groupForm.x_repay_rate = val;
        this.groupForm.y_repay_rate = 100 - val;
        this.$forceUpdate();
      }
    },
    xFeeRateInput() {
      this.$forceUpdate();
    },
    yPeriodNumChange(val) {
      val = Math.min(36, Math.max(1, Math.round(val)));
      this.groupForm.y_period_num = val;

      const feeItem = this.businessFeeList.find((item) => {
        return val >= item.stage_count_start && val <= item.stage_count_end;
      });
      if (feeItem) {
        this.groupForm.y_platform_fee_rate = feeItem.platform_fee_rate;
      }
    },
    yFeeRateInput() {
      this.$forceUpdate();
    },
    confirm() {
      if (this.repay_type == 1) {
        // 机构贴息：则没有机构管理费
        if (this.averageCapitalForm.platform_fee_pay_assume != 1) {
          this.averageCapitalForm.business_fee_extend = 0;
        }
        this.averageCapitalForm.business_id = this.business_id;
        this.averageCapitalForm.repay_type = this.repay_type;
        var rate =
          Number(this.averageCapitalForm.business_fee_extend) +
          Number(this.averageCapitalForm.platform_fee_rate);
        if (rate > 1.5) {
          this.$message.error("机构附加服务费+平台管理费率不能超过1.5");
        } else {
          this.createPackage(this.averageCapitalForm);
        }
      } else if (this.repay_type == 2) {
        if (this.first_pay_day_status == 1) {
          this.groupForm.first_repay_day = 0;
        }
        this.groupForm.y_repay_type = 1;
        // 先息后本：则 x段无本金、机构不贴息
        if (this.groupForm.x_repay_type == 3) {
          this.groupForm.x_repay_rate = 0;
          this.groupForm.y_repay_rate = 100;
          // this.groupForm.x_platform_fee_repay_assume = 1;
          // this.groupForm.y_platform_fee_repay_assume = 1;
        }
        // 机构贴息：则没有机构管理费
        if (
          this.groupForm.x_platform_fee_repay_assume != 1 ||
          this.groupForm.y_platform_fee_repay_assume != 1
        ) {
          this.groupForm.business_fee_extend = 0;
        }

        this.groupForm.business_id = this.business_id;
        this.groupForm.repay_type = this.repay_type;
        // this.createPackage(this.groupForm)
        var xRate =
          Number(this.groupForm.business_fee_extend) +
          Number(this.groupForm.x_platform_fee_rate);
        var yRate =
          Number(this.groupForm.business_fee_extend) +
          Number(this.groupForm.y_platform_fee_rate);
        if (xRate > 1.5) {
          this.$message.error("商家管理费+X段的平台管理费率不能超过1.5");
        } else if (yRate > 1.5) {
          this.$message.error("商家管理费+Y段的平台管理费率不能超过1.5");
        } else if (
          this.groupForm.x_repay_type == 1 &&
          this.groupForm.x_repay_rate < 10
        ) {
          this.$message.error("等额本金时X还款比例不能低于10%");
        } else {
          this.createPackage(this.groupForm);
        }
      }
    },
    async createPackage(form) {
      let res;
      this.package_id
        ? (res = await editPackage(form))
        : (res = await createPackage(form));
      if (res.code == 200) {
        this.close();
        this.$message.success("操作成功");
        this.$parent.initData();
      }
    },
    close() {
      this.dialogFormVisible = false;
      this.repay_type = 1;
      this.package_name = "";
      this.averageCapitalForm = {
        platform_fee_pay_assume: 1,
      };
      this.groupForm = {
        x_platform_fee_repay_assume: 1,
        y_platform_fee_repay_assume: 1,
      };
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.business-price {
  .el-radio {
    margin-right: 15px;
  }
  /deep/ .customW {
    border-radius: 10px !important;
    .el-dialog__header {
      border-bottom: 1px solid #e8eaec;
      .el-dialog__title {
        font-size: 14px;
        color: #17233d;
        font-weight: 700;
      }
    }
    .item-special {
      .el-form-item__label {
        line-height: 1;
      }
    }
    .el-dialog__footer {
      border-top: 1px solid #e8eaec;
    }
  }
}
</style>