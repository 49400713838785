<template>
  <div class="quoted-price">
    <div class="opdetcru">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/business/businessList' }"
          >商家列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>编辑报价</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="derive">
      <el-button
        size="small"
        type="success"
        v-if="managerType != 0 || getAuth('priceListOperate')"
        @click="createQuotedPrice()"
        >添加报价</el-button
      >
      <el-button
        size="small"
        type="success"
        v-if="managerType != 0 || getAuth('otherConfiguration')"
        @click="otherConfiguration()"
        >其他配置</el-button
      >
    </div>
    <div class="combined">
      <div class="combined-title">非组合还款</div>
      <el-table :data="isnCombinedData" header-cell-class-name="table_header">
        <el-table-column
          property="repay_type_text"
          label="还款方式"
          align="center"
        ></el-table-column>
        <el-table-column
          property="repay_period_numtext"
          label="期数"
          align="center"
        ></el-table-column>
        <el-table-column
          property="platform_fee_rate"
          label="平台管理费率（%）"
          align="center"
        ></el-table-column>
        <el-table-column
          property="platform_fee_pay_assume_text"
          label="平台管理费承担方"
          align="center"
        ></el-table-column>
        <el-table-column
          property="business_fee_extend"
          label="机构管理费率(%)"
          align="center"
        ></el-table-column>
        <el-table-column
          property="memo"
          label="备注"
          align="center"
        ></el-table-column>
        <el-table-column label="是否显示" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.is_view == 0">不显示</span>
            <span v-if="scope.row.is_view == 1">显示</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-if="managerType != 0 || getAuth('priceListOperate')"
              @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              v-if="
                (managerType != 0 || getAuth('priceListOperate')) &&
                scope.row.is_view == 1
              "
              size="small"
              @click="getNotShow(scope.row)"
              >隐藏</el-button
            >
            <el-button
              type="text"
              v-if="
                (managerType != 0 || getAuth('priceListOperate')) &&
                scope.row.is_view == 0
              "
              size="small"
              @click="getShow(scope.row)"
              >显示</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="managerType != 0 || getAuth('priceListOperate')"
              @click="getDelPackage(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="combined">
      <div class="combined-title">组合还款</div>
      <el-table :data="combinedData" header-cell-class-name="table_header">
        <el-table-column
          property="repay_type_text"
          label="还款方式"
          align="center"
        ></el-table-column>
        <el-table-column
          property="period_num_text"
          label="期数"
          align="center"
        ></el-table-column>
        <el-table-column
          property="platform_fee_text"
          label="平台管理费率(%)"
          align="center"
        ></el-table-column>
        <el-table-column
          property="platform_fee_pay_text"
          label="平台管理费承担方"
          align="center"
        ></el-table-column>
        <el-table-column
          property="business_fee_extend"
          label="机构管理费率(%)"
          align="center"
        ></el-table-column>
        <el-table-column
          property="first_pay_day_text"
          label="首付支付天数"
          align="center"
        ></el-table-column>
        <el-table-column
          property="memo"
          label="备注"
          align="center"
        ></el-table-column>
        <el-table-column label="是否显示" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.is_view == 0">不显示</span>
            <span v-if="scope.row.is_view == 1">显示</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-if="managerType != 0 || getAuth('priceListOperate')"
              @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              v-if="
                (managerType != 0 || getAuth('priceListOperate')) &&
                scope.row.is_view == 1
              "
              size="small"
              @click="getNotShow(scope.row)"
              >隐藏</el-button
            >
            <el-button
              type="text"
              v-if="
                (managerType != 0 || getAuth('priceListOperate')) &&
                scope.row.is_view == 0
              "
              size="small"
              @click="getShow(scope.row)"
              >显示</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="managerType != 0 || getAuth('priceListOperate')"
              @click="getDelPackage(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <other-configuration ref="otherConfiguration" />
    <quoted-price-model ref="quotedPriceModel" />
  </div>
</template>

<script>
import otherConfiguration from "./components/other-configuration.vue";
import quotedPriceModel from "./components/quoted-price-model.vue";
import { delPackage, listPackage, viewPackage } from "@/api/package.js";
import { getAuth } from "@/utils/index.js";
export default {
  name: "quoted-price",
  components: { otherConfiguration, quotedPriceModel },
  data() {
    return {
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      combinedData: [],
      isnCombinedData: [],
      business_id: "",
      payTypeList: {
        1: "等额本金",
        2: "组合还款",
      },
      isnPayTypeList: {
        1: "等额本金",
        3: "先息后本",
      },
      discountFeeList: {
        0: "否",
        1: "是",
      },
      platformFeePayList: {
        1: "学员",
        2: "机构",
      },
      serchForm: {
        business_id: "",
        repay_type: 0,
      },
    };
  },

  mounted() {
    this.business_id = this.$route.query.business_id;
    this.serchForm.business_id = this.$route.query.business_id;
    this.initData();
  },

  methods: {
    initData() {
      this.getIsnCombine();
      this.getCombined();
    },
    async getIsnCombine() {
      var that = this;
      this.serchForm.repay_type = 1;
      const { code, data } = await listPackage(this.serchForm);
      if (code == 200) {
        this.isnCombinedData = data.map((item) => {
          item.repay_type_text = that.payTypeList[item.repay_type];
          item.repay_period_numtext = item.repay_period_num + "月";
          item.platform_fee_pay_assume_text =
            that.platformFeePayList[item.platform_fee_pay_assume];
          return item;
        });
      }
    },
    async getNotShow(row) {
      this.$confirm("是否隐藏当前报价？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const param = {
            package_id: row.package_id,
            is_view: 0,
          };
          this.getViewPackage(param);
        })
        .catch(() => {});
    },
    async getShow(row) {
      this.$confirm("是否显示当前报价？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const param = {
            package_id: row.package_id,
            is_view: 1,
          };
          this.getViewPackage(param);
        })
        .catch(() => {});
    },
    async getViewPackage(param) {
      const { code } = await viewPackage(param);
      if (code == 200) {
        this.initData();
        this.$message({
          type: "success",
          message: "操作成功",
        });
      }
    },
    async getCombined() {
      var that = this;
      this.serchForm.repay_type = 2;
      const { code, data } = await listPackage(this.serchForm);
      if (code == 200) {
        this.combinedData = data.map((item) => {
          item.repay_type_text =
            "X: " +
            that.isnPayTypeList[item.x_repay_type] +
            "；Y: " +
            that.isnPayTypeList[item.y_repay_type];
          item.period_num_text = item.x_period_num + " + " + item.y_period_num;
          item.platform_fee_text =
            "X: " +
            item.x_platform_fee_rate +
            "；Y: " +
            item.x_platform_fee_rate;
          item.platform_fee_pay_text =
            "X: " +
            that.platformFeePayList[item.x_platform_fee_repay_assume] +
            "；Y: " +
            that.platformFeePayList[item.y_platform_fee_repay_assume];
          if (item.first_repay_day == 0) {
            item.first_pay_day_text = "一个月";
          } else {
            item.first_pay_day_text = item.first_repay_day + "天后";
          }
          return item;
        });
      }
    },

    createQuotedPrice() {
      this.$refs.quotedPriceModel.isShow('add',"", this.business_id);
    },
    edit(row) {
      this.$refs.quotedPriceModel.isShow('edit',row, this.business_id);
    },
    otherConfiguration() {
      this.$refs.otherConfiguration.isShow(this.business_id);
    },
    getDelPackage(row) {
      this.$confirm("是否删除当前报价？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const parmes = {
            package_id: row.package_id,
          };
          const { code } = await delPackage(parmes);
          if (code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.initData();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.quoted-price {
  .opdetcru {
    margin-bottom: 20px;
  }
  .derive {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .combined {
    margin-bottom: 20px;
    border: 1px solid #ebeef5;
    border-bottom: 0px;
    .combined-title {
      padding: 10px 20px;
      border-bottom: 1px solid #ebeef5;
    }
    /deep/ .table_header {
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
      font-size: 10px;
      .cell {
        padding: 0px 5px;
      }
    }
  }
}
</style>