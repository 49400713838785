<template>
  <div>
    <el-dialog
      title="其他配置"
      width="770px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form :model="form" size="small">
        <div>
          <div class="sub-title">提前结算管理费</div>
          <div class="classification">
            <el-form-item label="等额本息：" label-width="110px">
              <div class="flex">
                <el-input class="flex-1 item-value" v-model="form.single_pay_percent" placeholder="请输入费率"
                  oninput ="value=value.replace(/[^0-9.]/g,'')">
                  <i slot="suffix" style="width:50px;margin-right:10px;color:black">%</i>
                </el-input>
                <span class="ml_10">× 剩余账款</span>
              </div>
            </el-form-item>
            <el-form-item label="组合还款：" label-width="110px">
              <div class="flex">
                <div>
                  <el-input class="item-value" v-model="form.x_pay_percent" placeholder="请输入费率"
                    oninput ="value=value.replace(/[^0-9.]/g,'')">
                    <i slot="suffix" style="width:50px;margin-right:10px;color:black">%</i>
                  </el-input>
                  <span class="ml_10">× 剩余账款</span>
                </div>
                <span style="margin: 0 40px;">+</span>
                <div>
                  <el-input class="item-value" v-model="form.y_pay_percent" placeholder="请输入费率"
                    oninput ="value=value.replace(/[^0-9.]/g,'')">
                    <i slot="suffix" style="width:50px;margin-right:10px;color:black">%</i>
                  </el-input>
                  <span class="ml_10">× 剩余账款</span>
                </div>
              </div>
                
            </el-form-item>
          </div>
        </div>
        <div>
          <div class="sub-title">逾期费用项</div>
          <div class="classification">
            <el-form-item label="逾期宽限期：" label-width="110px">
              <div class="flex">
                <el-select class="item-value" v-model="form.exceed_fee_day" placeholder="请选择天数">
                  <el-option
                    v-for="item in gracePeriodList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <span class="ml_10">天</span>
              </div>
            </el-form-item>
            <el-form-item label="逾期罚金：" label-width="110px">
              <div class="flex">
                <el-input class="item-value" v-model="form.exceed_fee_rate" placeholder="请输入费率"
                  oninput ="value=value.replace(/[^0-9.]/g,'')">
                  <i slot="suffix" style="width:50px;margin-right:10px;color:black">%</i>
                </el-input>
                <span class="ml_10">× 剩余账款</span>
              </div>
            </el-form-item>
            <el-form-item label="逾期滞纳金：" label-width="110px">
              <div class="flex">
                <el-input class="item-value" v-model="form.late_fee_rate" placeholder="请输入费率"
                  oninput ="value=value.replace(/[^0-9.]/g,'')">
                  <i slot="suffix" style="width:50px;margin-right:10px;color:black">%</i>
                </el-input>
                <span class="ml_10">× 当期剩余应还</span>
              </div>
            </el-form-item>
          </div>
        </div>
        <div>
          <div class="sub-title">退款费用项</div>
          <div class="classification">
            <el-form-item label="退款费率：" label-width="110px">
              <div class="flex">
                <el-input class="item-value" v-model="form.refund_rate" placeholder="请输入费率"
                  oninput ="value=value.replace(/[^0-9.]/g,'')">
                  <i slot="suffix" style="width:50px;margin-right:10px;color:black">%</i>
                </el-input>
              </div>
            </el-form-item>
          </div>
        </div>
        <div>
          <div class="sub-title">快捷支付费用项</div>
          <div class="classification">
            <el-form-item label="快捷支付费率：" label-width="110px">
              <div class="flex">
                <el-input class="item-value" v-model="form.fast_pay_rate" placeholder="请输入费率"
                  oninput ="value=value.replace(/[^0-9.]/g,'')">
                  <i slot="suffix" style="width:50px;margin-right:10px;color:black">%</i>
                </el-input>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm()" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { gracePeriodList } from '@/assets/equipment/allSelect.json';
import {infoOtherPackage,editOtherPackage} from '@/api/package.js'
export default {
  name: "other-configuration",
  data() {
    return {
      dialogFormVisible:false,
      gracePeriodList:gracePeriodList,
      form:{
        single_pay_type:1,
        x_pay_type:1,
        y_pay_type:1,
        exceed_fee_type:1,
        late_fee_type:2,
      }
    };
  },
  methods: {
    async isShow(row) {
      this.dialogFormVisible = true
      const parme = {
        business_id:row
      }
      const {code,data} = await infoOtherPackage(parme)
      if(code == 200){
        this.form = data
      }
    },
    async confirm(){
      this.form.single_pay_type = 1
      this.form.x_pay_type = 1
      this.form.y_pay_type = 1
      this.form.exceed_fee_type = 1
      this.form.late_fee_type = 2
      const {code} = await editOtherPackage(this.form)
      if(code == 200){
        this.close()
        this.$message.success('操作成功')
        this.$parent.initData();
      }
    },
    close(){
      this.dialogFormVisible = false
      this.form = {
        single_pay_type:1,
        x_pay_type:1,
        y_pay_type:1,
        exceed_fee_type:1,
        late_fee_type:2,
      }
    },
    handleClose(done){
      done();
      this.close()
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/ .customW{
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title{
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .sub-title{
    background: #eee;
    padding: 8px 20px;
  }
  .classification{
    margin: 10px 20px;
    .content-form{
      margin: 0px 10px;
    }
    .item-value{
      width: 120px;
      max-width: 120px;
    }
  }
  .el-dialog__footer{
    border-top: 1px solid #e8eaec;
  }
}

</style>
