export function getAuth(val) {
  var authMenu = localStorage.getItem('auth')
  let authArr = []
  var show = false
  try {
    authArr = JSON.parse(authMenu)
    authArr.map((item) => {
      if (item.auth_key == val) {
        show = true
      }
    })
  } catch (err) {
    console.log(err)
  }
  return show
}
