import request from '@/utils/request'

// 编辑报价
export function editPackage (params) {
  return request.post('/api/editPackage', params)
}

// 报价列表
export function listPackage (params) {
  return request.post('/api/listPackage', params)
}

// 添加报价
export function createPackage (params) {
  return request.post('/api/createPackage', params)
}

// 报价详情
export function infoPackage (params) {
  return request.post('/api/infoPackage', params)
}

// 删除报价
export function delPackage (params) {
  return request.post('/api/delPackage', params)
}

// 其他配置
export function infoOtherPackage (params) {
  return request.post('/api/infoOtherPackage', params)
}

// 编辑其他配置
export function editOtherPackage (params) {
  return request.post('/api/editOtherPackage', params)
}


//主体费率列表
export function entityFeeList (params) {
  return request.post('/api/entityFeeList', params)
}

//商家费率列表
export function businessFeeList (params) {
  return request.post('/api/businessFeeList', params)
}

//是否显示
export function viewPackage (params) {
  return request.post('/api/viewPackage', params)
}